<template>
  <nav class="uk-navbar-container site-navigation" uk-navbar="mode: click">
    <div class="uk-navbar-left">
      <ul class="uk-navbar-nav">
        <li id="brandIcon" :class="{ active: $route.name === 'Home' }" class="left">
          <router-link :to="'/'">
            <Logo>
              <CenterLogo></CenterLogo>
            </Logo>
          </router-link>
        </li>
        <li class="affiliation">
          <router-link :to="'/'">
            <span class="uk-hidden@l">{{ SERVICE_ACRONYM }}</span>
            <div class="long uk-visible@l">{{ SERVICE_DESCRIPTION }}</div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="uk-navbar-center">
      <ul class="uk-navbar-nav">
        <li v-for="(config, name) of menuRoutes" :key="name">
          <router-link v-if="config.path" :to="{ name: config.name }">{{ name }}</router-link>
          <template v-else>
            <a>{{ name }}</a>
            <div uk-dropdown="mode: hover; pos: bottom-justify">
              <ul class="uk-nav uk-dropdown-nav">
                <li
                  v-for="(path, title) of config"
                  :key="title"
                  class="menuItem"
                  :class="{ 'uk-active': route.path === path.path }"
                >
                  <router-link :to="path">{{ title }}</router-link>
                </li>
              </ul>
            </div>
          </template>
        </li>
      </ul>
    </div>
    <div class="uk-navbar-right">
      <ul class="uk-iconnav uk-margin-right">
        <li v-if="darkmode === false">
          <a :uk-tooltip="`title: 'Switch to light mode'`" @click="setDarkmode(true)">
            <span class="icon inline">
              <IconBrightness5 />
            </span>
          </a>
        </li>
        <li v-else>
          <a :uk-tooltip="`title: 'Switch to dark mode'`" @click="setDarkmode(false)">
            <span class="icon inline">
              <IconBrightness3 />
            </span>
          </a>
        </li>
        <li v-if="!user">
          <a class="navbar-item login" uk-tooltip="title: Log in" @click="openLoginWindow">
            <span class="icon inline">
              <IconFingerprint />
            </span>
          </a>
        </li>
        <li v-else>
          <a class="logout" uk-tooltip="title: Log out" @click="logoutAndGoHome">
            <span class="icon inline">
              <IconExitToApp />
            </span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import * as actions from '../../store/graph/operations/index.js'
import Logo from "@/components/site/Logo.vue";
import CenterLogo from '@/components/site/CenterLogo.vue'
import IconFingerprint from '@/components/icons/Fingerprint.vue'
import IconBrightness3 from '@/components/icons/Brightness3.vue'
import IconBrightness5 from '@/components/icons/Brightness5.vue'
import IconExitToApp from '@/components/icons/ExitToApp.vue'
import { useUser } from '../use/useUser.js'
import { useDarkmode } from '../use/useDarkmode.js'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import config from '../../../../config.js';
import { getMenuRoutes } from '../../router/routes/index'

const {
  PRIMARY_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  SERVICE_ACRONYM,
  SERVICE_DESCRIPTION
} = config

export default {
  name: 'Navigation',
  components: {
    Logo,
    CenterLogo,
    IconFingerprint,
    IconBrightness3,
    IconBrightness5,
    IconExitToApp,
  },
  props: {
    plugins: {
      type: Array,
      default: () => []
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const { user } = useUser()
    const { darkmode, setDarkmode } = useDarkmode()
    const openLoginWindow = () => {
      let loginPath = route.path || '/enter'
      if (!loginPath.startsWith('/authenticate/login')) loginPath = '/authenticate/login' + loginPath
      router.push({ path: loginPath })
    }
    const logoutAndGoHome = () => {
      localStorage.removeItem('Authentication')
      store.dispatch(actions.LOGOUT, { username: user?.value.username })
      if (route.name !== 'Home') {
        router.push({ name: 'home' })
      }
    }
    const menuRoutes = getMenuRoutes()
    return {
      darkmode,
      logoutAndGoHome,
      openLoginWindow,
      route,
      PRIMARY_BACKGROUND_COLOR,
      PRIMARY_COLOR,
      SERVICE_ACRONYM: SERVICE_ACRONYM,
      SERVICE_DESCRIPTION: SERVICE_DESCRIPTION,
      menuRoutes,
      setDarkmode,
      user,
    }
  },
}
</script>

<style>
nav .uk-navbar-nav > li > a > *,
nav .uk-navbar-nav > li > a .icon *,
nav .uk-navbar-nav > li > a .icon path,
nav .uk-navbar-nav > li > a {
  color: v-bind(PRIMARY_COLOR) !important;
}
.uk-dropdown-nav {
  color: var(--smokeBlack) !important;
}
.uk-dropdown-nav > li > a:hover {
    color: black !important;
}
.uk-dropdown-nav .router-link-active {
    color: v-bind(PRIMARY_BACKGROUND_COLOR) !important;
}

.menuItem,
.menuItem *,
.menuItem.uk-active {
  color: black !important;
  font-size: 12px;
}
.router-link-active {
  color: v-bind(PRIMARY_BACKGROUND_COLOR) !important;
}
nav.site-navigation {
  display: flex;
  justify-content: space-between;
  background-color: v-bind(PRIMARY_BACKGROUND_COLOR) !important;
  /* color: v-bind(PRIMARY_COLOR) !important; */
}
nav.site-navigation .uk-navbar-left {
  margin-left: 0;
}

nav.site-navigation .uk-navbar-center {
  display: inline;
  position: initial;
  transform: none;
}
nav.site-navigation .uk-navbar-right {
  margin-left: initial;
}

nav.site-navigation img {
  max-height: 4rem !important;
}
</style>
