<template>
  <div
      class="logo"
      :class="{'with-shadow': shadow}"
  >
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    shadow: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped lang="stylus">
// https://hackmd.io/c5Q2pQrwRZi9ykKSgJSvhQ?both
.logo.with-shadow
  -webkit-filter: drop-shadow(12px 12px 25px rgba(0,0,0,0.6));
  filter: url(#drop-shadow);
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
</style>
